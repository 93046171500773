.team-member{
    margin: 0 20px;
}

.team-member-image{
    border-radius: 100%;
    overflow: hidden;
    border: 3px solid #333;
}

.team-member-image img{
    
}

.team-member h3{
    margin-top: 15px;
    text-align: center;
    font-size: 1.3em;
    line-height: 1.2em;
    margin-bottom: 10px;
    color: var(--main-color);
}

.team-member h4{
    text-align: center;
    line-height: 1.2em;
}

@media only screen and (max-width: 760px){
    .team-member{
        margin-bottom: 50px;
    }
    .team-member-image{
        border-radius: 100%;
        overflow: hidden;
        width: 180px;
        margin: 0 auto;
        border: 2px solid #fff;
    }
    
}