@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

@font-face {
    font-family: 'PantherEGO';
    src: url('../fonts/PantherEGO.woff2') format('woff2'),
         url('../fonts/PantherEGO.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

:root {
    --main-color: rgba(236,72,153);
}

body{
    background-color: #111;
    color: #fff;
    font-family: 'Rubik', sans-serif;
    line-height: 1.8;
    font-size: 1.2em;
}

.button{
    display: inline-block;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #ff83fb;
    color: #000;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all .3s ease;
}

.button:hover{
    background-color: #fc4af6;
}

.mint-qty{
    background: transparent;
    border: 1px solid #ec4899;
    outline: none;
    padding: 6px 12px;
    border-radius: 10px;
    width: 60px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-circular{
    background: rgb(129,140,248);
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    line-height: 50px;
    font-size: 1.2em;
    opacity: 0.7;
    outline: none;
    transition: all 0.2s ease-in-out;
}

.button-circular:hover{
    opacity: 1;
}

.col-1{
    max-width: 960px;
    margin: 0 auto;
    padding: 0 20px;
}

.text-main-color{
    color: var(--main-color);
}

h2{
    font-weight: 500;
    font-size: 2.3em;
    margin-bottom: 1em;
    color: var(--main-color);
}

a{
    color: var(--main-color);
}

.abs-centered{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

p{
    margin-bottom: 1em;
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}




@media only screen and (max-width: 760px){
    .deco-left,
    .deco-right{
        display: none;
    }
}
