@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap);
@font-face {
    font-family: 'PantherEGO';
    src: url(/static/media/PantherEGO.cf0b3700.woff2) format('woff2'),
         url(/static/media/PantherEGO.87d759d8.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

:root {
    --main-color: rgba(236,72,153);
}

body{
    background-color: #111;
    color: #fff;
    font-family: 'Rubik', sans-serif;
    line-height: 1.8;
    font-size: 1.2em;
}

.button{
    display: inline-block;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #ff83fb;
    color: #000;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all .3s ease;
}

.button:hover{
    background-color: #fc4af6;
}

.mint-qty{
    background: transparent;
    border: 1px solid #ec4899;
    outline: none;
    padding: 6px 12px;
    border-radius: 10px;
    width: 60px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-circular{
    background: rgb(129,140,248);
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    line-height: 50px;
    font-size: 1.2em;
    opacity: 0.7;
    outline: none;
    transition: all 0.2s ease-in-out;
}

.button-circular:hover{
    opacity: 1;
}

.col-1{
    max-width: 960px;
    margin: 0 auto;
    padding: 0 20px;
}

.text-main-color{
    color: rgba(236,72,153);
    color: var(--main-color);
}

h2{
    font-weight: 500;
    font-size: 2.3em;
    margin-bottom: 1em;
    color: rgba(236,72,153);
    color: var(--main-color);
}

a{
    color: rgba(236,72,153);
    color: var(--main-color);
}

.abs-centered{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

p{
    margin-bottom: 1em;
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}




@media only screen and (max-width: 760px){
    .deco-left,
    .deco-right{
        display: none;
    }
}

.header-desktop{
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background-color: rgba(0,0,0,0.8);
    align-items: center;
    display: flex;
}

.header-mobile{
    display: none;
}


.logo img{
    height: 50px;
    max-width: none;
}

.menu{
    flex-grow: 1;
}

.menu a{
    margin: 0 20px;
    color: #fff;
    font-size: 1.5em;
    text-decoration: none;
}

.header a:hover{
    text-decoration: underline;
}


.social a{
    display: inline-block;
    transition: all 0.2s ease-out;
    opacity: 1;
    margin-right: 15px;
    vertical-align: middle;
}

.social a:hover{
    opacity: 0.7;
}

.social img{
    width: 40px;
    height: 40px;
}

.burger{
    position: absolute;
    left: 15px;
    top: 15px;
}

.burger img{
    width: 50px;
    height: 50px;
}

.mobile-menu{
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 300px;
    z-index: 10;
    transform: translateX(-100%);
    background-color: #000;
}

.mobile-menu-logo{
    width: 200px;
    margin: 10px 0 0 10px;
}

.mobile-menu ul{
    position: absolute;
    left: 20px;
    top: 140px;
    font-size: 1.8em;
}

.mobile-menu-close{
    position: absolute;
    right: 0px;
    top: 0px;
    opacity: 0.5;
}

.mobile-menu-close img{
    width: 60px;
}

.mobile-menu .social{
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 960px){
    .header-desktop{
        display: none;
    }   
    .header-mobile{
        position: fixed;
        left: 0;
        right: 0;
        z-index: 9;
        background-color: rgba(0,0,0,0.8);
        display: block;
        -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
    }
    .mobile-menu{
        display: block;
    }
    
}
.roadmap{
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 90vw;
}

.roadmap-circle{
    position: relative;
    list-style-type: none;
    margin: auto;
    padding: 0;
}

.roadmap-item{
    display: block;
    position: relative;
    padding-left: 130px;
    margin-bottom: 100px;
}

.roadmap-desc{
    padding-left: 16px;
}

.roadmap-desc h1{
    font-size: 18px;
    font-family: aktiv-grotesk-extended, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: left;
}

.roadmap-desc p{
    
}

.roadmap-desc-back{
    position: absolute;
    left: 8em;
    width: 250px;
}


.oval-shape{
    position: absolute;
    left: 0;
    top: 0;
    background: #04020C;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #fff;
    text-align: center;
}

.oval-shape span{
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 30px;
    color: #fff;
    font-family: aktiv-grotesk-extended, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.bullet-item{
    display: block;
    padding-left: 40px;
    position: relative;
    margin-top: 20px;
}

.bullet{
    width: 28px;
    position: absolute;
    top: 5px;
    left: 0;
}

@media only screen and (max-width: 1100px){

}

@media only screen and (max-width: 1100px){
    .roadmap{
        background-image: none;
        padding-left: 0px;
    }
    .roadmap-circle{
        position: static;
        width: auto;
        height: auto;
    }
    .roadmap-item{
        display: block;
        position: relative;
        padding-left: 65px;
        transform: none !important;
        padding-bottom: 40px;
    }
    .oval-shape{
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: 50px;
        background: none;
        border: 0;
        padding-top: 6px;
    }
    .oval-shape span{
        line-height: 1em;
        font-size: 1.3em;
    }
    .roadmap-desc-back{
        position: static;
        width: 100%;
    }
    .roadmap-desc-front{
        position: static;
        width: 100%;
    }
}



.team-member{
    margin: 0 20px;
}

.team-member-image{
    border-radius: 100%;
    overflow: hidden;
    border: 3px solid #333;
}

.team-member-image img{
    
}

.team-member h3{
    margin-top: 15px;
    text-align: center;
    font-size: 1.3em;
    line-height: 1.2em;
    margin-bottom: 10px;
    color: var(--main-color);
}

.team-member h4{
    text-align: center;
    line-height: 1.2em;
}

@media only screen and (max-width: 760px){
    .team-member{
        margin-bottom: 50px;
    }
    .team-member-image{
        border-radius: 100%;
        overflow: hidden;
        width: 180px;
        margin: 0 auto;
        border: 2px solid #fff;
    }
    
}
.faq-row{
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.faq-row .row-content-text{
    padding-bottom: 20px !important;
}


.hero{
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

.hero-logo{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
}


.header-cta{
    display: inline-block;
    padding: 10px 20px;
    margin-right: 20px;
    border-radius: 10px;
    background-color: #ff83fb;
    color: #000;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all .3s ease;
}

.hero-cta.white{
    background-color: transparent;
    color: #fff;
    border: 3px solid #fff;
}

.hero-cta:hover, .header-cta:hover{
    background-color: #fc49f6;
}

.hero-cta.white:hover{
    background-color: #700f6d;
}
    
.hero-cta{
    display: inline-block;
    padding: 20px 30px;
    border-radius: 10px;
    background-color: #ff83fb;
    color: #000;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all .3s ease;
}

.hero-cta:hover{
    background-color: #fc49f6;
}

.hero-image{
    /* background-image: url(../assets/img/hero.jpg);
    background-repeat: no-repeat;
    background-size: cover; */
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.hero-overlay{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(0,0,0,0.3);
}

.hero-content{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
}

@keyframes intro {
    from {
        transform: translate(-50%, 0);
    }
    to {
        transform: translate(-50%, -60%);
    }
}

.hero-deco{
    background-image: url(/static/media/intro-panther.5fa49df2.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 50%;
    bottom: -1000px;
    width: 1000px;
    height: 1000px;
    z-index: 2;
    animation: intro 4s normal forwards ease-in-out;
}

.p-canvas-webgl{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@media only screen and (max-width: 760px){
    .header-cta{
        display: block;
        margin: 20px auto;
    }
    .hero-deco{
        background-image: url(/static/media/intro-panther.5fa49df2.png);
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 50%;
        bottom: -300px;
        width: 300px;
        height: 300px;
        margin-bottom: -50px;
        z-index: 2;
        animation: intro 2s normal forwards ease-in-out;
    }
    .hero-content .abs-centered{
        position: relative;
        top: calc(50% + 40px);
        max-width: 70%;
    }    
    .hero{
        position: relative;
        min-height: 50vh;
    }
    .hero-image{
        background-size: auto 100%;
        background-position: center center;
    }
}



.countdown {
    padding: 20px 40px;
    border-radius: 5;
    max-width: 600px;
    margin: 0 auto;
}

.countdown .row{
    display:flex;
    max-width: 500;
    margin: 0 auto;
}

.countdown .col{
    text-align: center;
    width: 25%;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    margin-right: 29;
    padding-right: 30;
}

.countdown .col-last{
    border-right: none;
    margin-right: 0;
    padding-right: 0;
}

.countdown .number{
    font-size: 3rem;
    color: #6ce5b7;
}

.countdown .label{
    font-size: 1rem;
    text-transform: uppercase;
}

@media only screen and (max-width: 760px){
    .countdown .number{
        font-size: 1.5em;
    }
    .countdown .label{
        font-size: 0.7em;
    }
}
