
.hero{
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

.hero-logo{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
}


.header-cta{
    display: inline-block;
    padding: 10px 20px;
    margin-right: 20px;
    border-radius: 10px;
    background-color: #ff83fb;
    color: #000;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all .3s ease;
}

.hero-cta.white{
    background-color: transparent;
    color: #fff;
    border: 3px solid #fff;
}

.hero-cta:hover, .header-cta:hover{
    background-color: #fc49f6;
}

.hero-cta.white:hover{
    background-color: #700f6d;
}
    
.hero-cta{
    display: inline-block;
    padding: 20px 30px;
    border-radius: 10px;
    background-color: #ff83fb;
    color: #000;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all .3s ease;
}

.hero-cta:hover{
    background-color: #fc49f6;
}

.hero-image{
    /* background-image: url(../assets/img/hero.jpg);
    background-repeat: no-repeat;
    background-size: cover; */
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.hero-overlay{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(0,0,0,0.3);
}

.hero-content{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
}

@keyframes intro {
    from {
        transform: translate(-50%, 0);
    }
    to {
        transform: translate(-50%, -60%);
    }
}

.hero-deco{
    background-image: url(../assets/img/intro-panther.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 50%;
    bottom: -1000px;
    width: 1000px;
    height: 1000px;
    z-index: 2;
    animation: intro 4s normal forwards ease-in-out;
}

.p-canvas-webgl{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@media only screen and (max-width: 760px){
    .header-cta{
        display: block;
        margin: 20px auto;
    }
    .hero-deco{
        background-image: url(../assets/img/intro-panther.png);
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 50%;
        bottom: -300px;
        width: 300px;
        height: 300px;
        margin-bottom: -50px;
        z-index: 2;
        animation: intro 2s normal forwards ease-in-out;
    }
    .hero-content .abs-centered{
        position: relative;
        top: calc(50% + 40px);
        max-width: 70%;
    }    
    .hero{
        position: relative;
        min-height: 50vh;
    }
    .hero-image{
        background-size: auto 100%;
        background-position: center center;
    }
}


